<template>
	<screen class="launch-view" size="medium">
		<h1>
			Engage with customers, <br />
			every time they checkout.
		</h1>
		<div class="congrats-icon" />
		<p>
			The RoundUp App is the #1 fundraising tool that automatically rounds
			up users’ credit or debit card transactions and donates the "change"
			to your selected nonprofit, showing your social imact, driving
			customer loyalty.
		</p>
		<div class="welcome-actions">
			<ui-button
				class="white"
				@click="navigate({ name: 'register' }, 'up')"
				>Register your Store</ui-button
			>
			<ui-button class="white" @click="navigate({ name: 'login' }, 'up')"
				>Store Login</ui-button
			>
		</div>
	</screen>
</template>

<script>
import UiButton from '../../ui/Button'
import Screen from '../../ui/Screen'
import Tutorial from '../../ui/Tutorial'
import Modal from '../../ui/Modal'

export default {
	name: 'launch-view',
	components: { UiButton, Screen, Tutorial, Modal },
	data() {
		return {
			showVideo: false
		}
	},
	methods: {},
	mounted() {}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.launch-view {
	color: #fff;
}
.screen {
	&.launch-view {
		p,
		h1 {
			color: white;
		}
		h1 {
			font-size: 1.75rem;
		}
		text-align: center;
		background-image: linear-gradient(#1cd4e3, #3770fe, #3770fe);
		.welcome-actions {
			width: 100%;
			display: flex;
			justify-content: space-evenly;
		}
		.congrats-icon {
			background: url('../../../assets/cardSwipe.png');
			background-size: 100% 100%;
			width: 300px;
			height: 192.86px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: auto;
		}
		.play {
			background: url('../../../assets/playButton.svg');
			background-size: 100% 100%;
			width: 75px;
			height: 75px;
			border-radius: 36.5px;
			display: inline-block;
			cursor: pointer;
			&:hover {
				box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
				transition: opacity 0.3s ease-in-out;
			}
		}
	}
}
</style>
