<template>
	<section class="tutorial">
		<div class="tutorial-slides">
			<slot />
		</div>
	</section>
</template>

<script>
export default {
	name: 'roundup-tutorial'
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.tutorial {
	margin-left: -25px;
	margin-right: -25px;
	margin-top: -25px;
	overflow: hidden;
	.tutorial-slides {
		height: 520px;
		display: flex;
		overflow-x: auto;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
	}
	.tutorial-slide {
		position: relative;
		width: 100%;
		color: $roundup-dark-grey;
		padding: 25px 0;
		margin-right: 80px;
		flex-shrink: 0;
		.slide-text {
			width: 100%;
			position: absolute;
			top: 240px;
			left: 0px;
			padding: 0 40px;
		}
		h2 {
			font-family: 'Bitter', serif;
			text-align: center;
			margin: 15px 10px;
		}
		p {
			text-align: center;
		}
		img {
			position: relative;
			display: block;
		}
	}
}
</style>
